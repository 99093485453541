/* Image Animation */
.fade-in-image {
  z-index:9999999;
  opacity: 0;
  transition: opacity 3s
}

.fade-in-image.visible {
  opacity: 1;
  transform: translateX(0);
}

/* Text Animation */
.fade-in-text {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 5s, transform 2s;
}

.fade-in-text.visible {
  opacity: 1;
  transform: translateX(0);
}
