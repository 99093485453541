/* Image Animation */
.fade-in-image {
  z-index:9999999;
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 0s, transform 3s;
}

.fade-in-image.visible {
  opacity: 1;
  transform: translateX(0);
}

/* Text Animation */
.fade-in-text {
  will-change: opacity, transform;

  opacity: 0;
  transform: translateY(100px);
}

.fade-in-text.visible {
  animation: fadeInText 2s forwards, slideInText 1s forwards;
}


/* Text Animation */
.fade-in-text2 {
  opacity: 0;
  transform: translateY(100px);
}

/* Text Animation */
.fade-in-text3 {
  opacity: 0;
  transform: translateY(100px);
}

.delay-005 {
  transition-delay: 0.05s;
}

.delay-006 {
  transition-delay: 0.05s;
}

.delay-007 {
  transition-delay: 0.05s;
}



.fade-in-text2.visible {
  animation: fadeInText 2s forwards 0.4s, slideInText 2s forwards 0.1s;
}

@keyframes fadeInText {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes slideInText {
  from { transform: translateY(100px); }
  to   { transform: translateX(0px); }
}
/* Image throw out animation */
.fade-out-image {
  opacity: 0;
  transform: translateX(-500px);
}

@keyframes touchScreen {
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg); /* adjust rotation amount as needed */
  }
}

.hand-animation {
  animation: touchScreen 1s infinite alternate;
}

.hand {
    width: 20%;
    border-radius: 12px;
    opacity: 1;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    position: absolute;
    bottom: 36%;
    left: 39%;
}


.waitlist-float {
    border-radius: 13px;
    opacity: 0.7;
    position: absolute;
    bottom: 28.4%;
    right: 45%;

    transform-origin: 40% 100%; /* 100% for rightmost edge, 100% for bottom edge */
    animation: rotateAnimation 3s infinite, zoomAppear 0.5s forwards;

}


.menu-float {
    position: absolute;
    top: 27%;
    right:75%;
    width: 20%;
    zIndex: 998;
    transform-origin: 30% 100%; /* 100% for rightmost edge, 100% for bottom edge */
    animation: rotateAnimation 3s infinite, zoomAppear 0.5s forwards;
}

.togo-float  {

    position: absolute;
    top: 40%;
    left: 69%;
    bottom: 40%;
    width: 28%;
    zIndex: 99988;
    opacity:1;
    transform-origin: 0% 0%; /* 100% for rightmost edge, 100% for bottom edge */
    animation: zoomAppear 0.9s forwards;

}



@keyframes rotateAnimation {
  0% {
      transform: rotate(0deg);
  }
  50% {
      transform: rotate(2deg);
  }
  100% {
      transform: rotate(0deg);
  }
}

@keyframes zoomAppear {
  0% {
      transform: scale(0.2) translateX(100%);
  }
  100% {
      transform: scale(1) translateX(0);
  }
}

.hand-move {
    width: 20%;
    border-radius: 12px;
    opacity: 1;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    position: absolute;
    bottom: 1%;
    left: 32%;

}

.block {

	background: linear-gradient(0deg, #000, #272727);
}

.block:before, .block:after {
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
	background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094,
		#0000ff, #00ff00,#ffff00, #ff0000);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	z-index: -1;
	animation: steam 20s linear infinite;
}

@keyframes steam {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.block:after {
	filter: blur(50px);
}


.fade-transition {
    transition: opacity 20s ease-out;
}
